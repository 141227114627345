.nav {
	display: none;
	width: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	background: rgba(black, 0.92);
	z-index: 99999999;
	color: white;
	overflow: auto;

	& button {
		margin-right: 1rem;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		overflow: auto;
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-titulo {
		color: white;
		font-size: 1.5rem;
		font-weight: 600;
		padding: .9rem 0 1.8rem 0;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: white;
		font-size: 1.2rem;
		font-weight: 400;
		margin: 0;
		padding: .6rem 0;
		@include border-box;
		transition: all .25s;
		border-left: solid 2px transparent;
		& i{
			color: white;
		}
		&:hover {
			font-weight: 500;
			padding-left: 1rem;
			border-left: solid 2px $colorTerciary;
		}
	}

	& .vermais{
		color: white;
		font-size: 1rem;
		font-weight: 400;
		margin: 0;
		padding: .6rem 0;
		@include border-box;
		transition: all .25s;
		border-left: solid 2px transparent;
		& i{
			display: none;
			vertical-align: middle;
			font-size: .9rem;
			color: white;
			transition: all .25s;
		}
		&:hover {
			font-weight: 500;
			padding-left: 1rem;
			border-left: solid 2px $colorTerciary;
			& i{
				display: inline-block;
			}
		}
	}

	& .itens_acoes_nav_menu{
		color: white;
		font-size: 1.2rem;
		font-weight: 400;
		margin: 0;
		padding: .6rem 0;
		@include border-box;
		transition: all .25s;
		border-left: solid 2px transparent;
		&:hover {
			font-weight: 500;
			padding-left: 1rem;
			border-left: solid 2px $colorTerciary;
		}
	}
}

@media all and (max-width: 1023px){
	.nav{
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}