.imo_telefones_btn{
    position: fixed !important;
	bottom: 2.5rem !important;
	right: 30px !important;
	z-index: 999;
       
    & #whatsapp_lista_telefones{
        padding: .5rem;
        position: fixed;
        bottom: 2.5rem;
        right: 8rem;
        & p{
            background-color: white;
            border: solid 1px $gray3;
            padding: .5rem 1.5rem;
            font-size: 1rem;
            font-weight: 500;
            color: $gray1;
            border-radius: .2rem;
            text-align: center;
            user-select: none;
            cursor: pointer;
            &:hover{
                background-color: $gray5;
            }
        }
        
    }

    & #wpp_pulsante{
        width: 100%;
		display: block;
		background-color: #25d366;
		margin: auto;
        width: 5rem;
        height: 5rem;
		border-radius: 50%;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
		-webkit-animation: pulse 1.5s infinite;
        position: relative;
        cursor: pointer;

        & i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 2.2rem;
            font-weight: 500;
        }

        & .pulse-button:hover {
			-webkit-animation: none;
		}


		@-webkit-keyframes pulse {
			0% {
				@include transform(scale(.9));
			}
			70% {
				@include transform(scale(1));
			box-shadow: 0 0 0 25px rgba(#25d366, 0);
			}
			100% {
				@include transform(scale(.9));
				box-shadow: 0 0 0 0 rgba(#25d366, 0);
			}
		}
    }
}

.pai_section_dialog{
    position: fixed !important;
	bottom: 2.5rem !important;
	right: 8rem !important;
	z-index: 9997;
    
    & .section_dialog{
        display: block;
        width: 100%;
        padding: 1rem 1rem 2rem 1rem;
        border-radius: .5rem;
        background-color: white;
        position: relative;
        border: solid 2px $gray4;
        box-shadow: 0 0 20px 2px rgba(black, .2);
        & .btn_fechar_form_zap{
            position: absolute;
            top: -1rem;
            right: -1rem;
            background-color: #25d366;
            border-radius: 50%;
            padding: .5rem;
            border: solid 3px white;
            transition: all .15s;
            height: 2.5rem;
            width: 2.5rem;
            cursor: pointer;
            & i{
                color: white;
                text-align: center;
                padding: 0 0 0 3px;
            }
            &:hover{
                background-color: white;
                border: solid 3px #25d366;
                & i{
                    color: #25d366;
                }
            }
        }

        & .dialog_wpp{
            display: block;
            width: 100%;
            padding: 0 2rem;
            & h2{
                text-align: center;
                font-size: 1.5rem;
                font-weight: 500;
                color: $colorTerciary;
            }
            & .under_p{
                text-align: center;
                width: 4rem;
                height: 2px;
                margin: auto;
                background-color: #25d366;
            }
            & .inputs_wpp{
                display: block;
                width: 100%;
                & .inputs_dados{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0;
                    padding: 0 1rem 0 0;
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: 1rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & input{
                        border: solid 1px $gray4;
                        border-radius: .2rem;
                        width: 100%;
                        display: block;
                        padding: .7rem 1rem;
                    }
        
                }
                & .input_msg{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0;
                    padding: 0 0 0 1rem;
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: 1rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & textarea{
                        border: solid 1px $gray4;
                        border-radius: .2rem;
                        width: 100%;
                        height: 120px;
                        display: block;
                        padding: .7rem 1rem;
                    }

                    & button{
                        width: 100%;
                        border: none;
                        border-radius: 2rem;
                        background-color: #25d366;
                        color: white;
                        font-size: 1rem;
                        font-weight: 600;
                        padding: .5rem 1rem;
                        cursor: pointer;
                        & i{
                            color: white;
                            font-size: 1.5rem;
                            font-weight: 400;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}

@media all and(max-width: 1023px){
    .imo_telefones_btn{
        position: fixed !important;
        bottom: 2rem !important;
        right: 1rem !important;
        z-index: 999;
           
        & #whatsapp_lista_telefones{
            padding: .5rem;
            position: fixed;
            bottom: 2rem;
            right: 7rem;
            & p{
                background-color: white;
                border: solid 1px $gray3;
                padding: .5rem 1.5rem;
                font-size: 1rem;
                font-weight: 500;
                color: $colorTerciary;
                border-radius: .2rem;
                text-align: center;
                user-select: none;
                cursor: pointer;
                &:hover{
                    border: solid 1px white;
                    background-color: $colorPrimary;
                    color: white;
                }
            }
            
        }
    
    }

    .pai_section_dialog{
        position: fixed !important;
        bottom: 2.5rem !important;
        right: 2.5rem !important;
        z-index: 9997;
        
        & .section_dialog{
            display: block;
            width: 80vw;
            padding: 1rem 2rem;
            border-radius: .5rem;
            background-color: white;
            position: relative;
            border: solid 2px $gray4;
            box-shadow: 0 0 20px 2px rgba(black, .2);
            & .btn_fechar_form_zap{
                position: absolute;
                top: -1rem;
                right: -1rem;
                background-color: #25d366;
                border-radius: 50%;
                padding: .5rem;
                border: solid 3px white;
                transition: all .15s;
                height: 2.5rem;
                width: 2.5rem;
                cursor: pointer;
                & i{
                    color: white;
                    text-align: center;
                    padding: 0 0 0 3px;
                }
                &:hover{
                    background-color: white;
                    border: solid 3px #25d366;
                    & i{
                        color: #25d366;
                    }
                }
            }
    
            & .dialog_wpp{
                display: block;
                width: 100%;
                padding: 0;
                padding-right: 10px;
                @include border-box;
                height: 68vh;
                max-height: 500px;
                overflow: auto;

                &::-webkit-scrollbar{width: 2px; height: 10px;}
                &::-webkit-scrollbar-track-piece{background-color: #FFF;}
                &::-webkit-scrollbar-thumb:vertical{height: 10px; background-color: lime;}
                &::-webkit-scrollbar-thumb:horizontal{width: 2px; background-color: lime;}
                &::-ms-clear {width: 0; height: 0;}
                &::-ms-reveal {width: 0; height: 0;}

                & h2{
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: $gray2;
                }
                & .under_p{
                    text-align: center;
                    width: 4rem;
                    height: 2px;
                    margin: auto;
                    background-color: #25d366;
                }
                & .inputs_wpp{
                    display: block;
                    width: 100%;
                    & .inputs_dados{
                        width: 100%;
                        display: block;
                        margin: 0 0 1rem 0;
                        padding: 0;
                        
                        & p{
                            margin: 0;
                            line-height: 1.5rem;
                            font-size: 1rem;
                            font-weight: 400;
                            text-align: left;
                            color: $gray2;
                        }
            
                        & input{
                            border: solid 1px $gray4;
                            border-radius: .2rem;
                            width: 100%;
                            display: block;
                            padding: .7rem .5rem;
                        }
            
                    }
                    & .input_msg{
                        width: 100%;
                        display: block;
                        margin: 0;
                        padding:0;
                        
                        & p{
                            margin: 0;
                            line-height: 1.5rem;
                            font-size: 1rem;
                            font-weight: 400;
                            text-align: left;
                            color: $gray2;
                        }
            
                        & textarea{
                            border: solid 1px $gray4;
                            border-radius: .2rem;
                            width: 100%;
                            height: 120px;
                            display: block;
                            padding: .7rem 1rem;
                        }
                    }
                }
            }
        }
    }
}