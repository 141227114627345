.formsimple-input-bc {
	background-color: white;
	border: solid 1px $gray4;
	border-radius: .1rem;
	color: $gray1;
	display: inline-block;
	padding: 0 1rem;

	&:hover, &:visited, &:active, &:focus{
		border: solid 1px $gray4;
	}
}